import { useCallback, useEffect, useState } from "react";

interface WindowDimensions {
  width: number;
  height: number;
}

/**
 * Custom hook to get the dimensions of the window
 * @returns [windowDimensions, checkMobile]
 */
const useWindowDimensions = (): [WindowDimensions, boolean] => {
  const [checkMobile, setCheckMobile] = useState<boolean>(false);

  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>({
    width: 0,
    height: 0,
  });

  const getDimensions = useCallback(() => {
    const { innerWidth: width, innerHeight: height } = window;
    setWindowDimensions({
      width,
      height,
    });

    const { userAgent } = navigator;

    if (/mobile/i.test(userAgent) || /tablet/i.test(userAgent)) {
      setCheckMobile(true);
    } else {
      setCheckMobile(false);
    }
  }, [setWindowDimensions]);

  useEffect(() => {
    getDimensions();
    window.addEventListener("resize", getDimensions);
    return () => window.removeEventListener("resize", getDimensions);
  }, [getDimensions]);

  return [windowDimensions, checkMobile];
};

export default useWindowDimensions;
