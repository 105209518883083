import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import ComingSoon from "src/pages/coming-soon";
import { RootState } from "src/store";
import Default from "./Default";

const AppRouter = () => {
  const isAuth = useSelector((state: RootState) => state.auth.isAuth);

  // const publicPageRoutes = publicRoutes.map(({ label, path, component }) => {
  //   return (
  //     <Route
  //       key={label}
  //       path={`${path}`}
  //       element={component}
  //     />
  //   );
  // });

  // const authPageRoutes = authRoutes.map(({ label, path, component }) => {
  //   return (
  //     <Route
  //       key={label}
  //       path={`${path}`}
  //       element={!isAuth ? component : <Navigate to="/dashboard" />}
  //     />
  //   );
  // });

  console.log(isAuth);

  // const privatePageRoutes = privateRoutes.map(({ label, path, component }) => {
  //   return (
  //     <Route
  //       key={label}
  //       path={`${path}`}
  //       element={isAuth ? component : <Navigate to="/auth/sign-in" />}
  //     />
  //   );
  // });

  return (
    <Routes>
      <Route
        path="/"
        element={<Default />}
      >
        <Route
          path="/"
          element={<ComingSoon />}
        />

        {/* public routes */}
        {/* <>{publicPageRoutes}</> */}

        {/* auth routes */}
        {/* <>{authPageRoutes}</> */}

        {/* private routes */}
        {/* <>{privatePageRoutes}</> */}

        {/* catch all */}
        <Route
          path="*"
          element={<Navigate to={isAuth ? "/" : "/auth/sign-in"} />}
        />
      </Route>
    </Routes>
  );
};

export default AppRouter;
