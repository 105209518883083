import React from "react";
import "./style.scss";

interface ButtonProps {
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
  variant?: string;
  color?: string;
  onClick?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
}

const Button: React.FC<ButtonProps> = ({ className, style, children, variant, color, onClick, disabled, type }) => {
  return (
    <button
      type={type}
      className={`btn ${color}${variant ? "-" + variant : ""} ${className ?? ""} ${disabled ? "disabled" : ""}`}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
