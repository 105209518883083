import { createSlice } from "@reduxjs/toolkit";

type AuthState = {
  isAuth: null | boolean;
  type: null | "User" | "Creator" | "Super Producer";
  profile: {
    uuid?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    validated?: boolean;
    validationToken?: string;
    validationExpires?: string;
    forgotPasswordToken?: string;
    forgotPasswordExpires?: string;
  };
};

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuth: null,
    type: null,
    profile: {
      uuid: "",
      firstName: "",
      lastName: "",
      email: "",
      validationToken: undefined,
      validationExpires: undefined,
      forgotPassword: undefined,
      forgotPasswordExpires: undefined,
    },
  } as AuthState,
  reducers: {
    signUp(state, action) {
      state.profile = { ...state.profile, ...action.payload };
    },
    setValidationToken(state, action) {
      state.profile.validationToken = action.payload;
    },
    setValidationExpires(state, action) {
      state.profile.validationExpires = action.payload;
    },
    setForgotPasswordToken(state, action) {
      state.profile.forgotPasswordToken = action.payload;
    },
    setForgotPasswordExpires(state, action) {
      state.profile.forgotPasswordExpires = action.payload;
    },
    setUUID(state, action) {
      state.profile.uuid = action.payload;
    },
    setEmail(state, action) {
      state.profile.email = action.payload;
    },
    setAuth(state, action) {
      state.isAuth = action.payload;
    },
    setType(state, action) {
      state.type = action.payload;
    },
  },
});

export const {
  signUp,
  setValidationToken,
  setValidationExpires,
  setUUID,
  setEmail,
  setForgotPasswordToken,
  setForgotPasswordExpires,
  setAuth,
  setType,
} = authSlice.actions;
export default authSlice.reducer;
