import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import auth from "./auth.reducer";

const reducers = combineReducers({
  auth: persistReducer({ key: "auth", storage }, auth),
});

export default reducers;
