import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleCheck } from "src/api/auth/auth.service";
import { RootState } from "src/store";
import { setAuth, setType } from "src/store/reducers/auth.reducer";
import AppRouter from "./AppRouter";
import PrivateContainer from "./PrivateContainer";
import PublicContainer from "./PublicContainer";

const DefaultLayout = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector((state: RootState) => state.auth.isAuth);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const res = await handleCheck();
        dispatch(setAuth(res.data.authenticated));
        dispatch(setType(res.data.type));
      } catch (err) {
        dispatch(setAuth(false));
      }
    };

    checkAuth();
  }, [dispatch]);

  return (
    <>
      {isAuth == true ? (
        <PrivateContainer>
          <AppRouter />
        </PrivateContainer>
      ) : null}
      {isAuth == false ? (
        <PublicContainer>
          <AppRouter />
        </PublicContainer>
      ) : null}
    </>
  );
};

export default DefaultLayout;
