import { MotionValue, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { Typography } from "../../../components/common/Base";
import { EmailSubscription, HeroSlider } from "../../../components/common/Widgets";
import "./style.scss";

function useParallax(motionValue: MotionValue, distance: number) {
  return useTransform(motionValue, [0, 1], [-distance, distance]);
}

// TODO: This function is not used anywhere in the codebase. Remove it.
// function useParallaxSection(value) {
// 	return useTransform(value, [0, 1], [0, 1024]);
// }

const ComingSoon = () => {
  const mainRef = useRef(null);
  const [windowDimensions] = useWindowDimensions();

  const { scrollYProgress } = useScroll({ target: mainRef });
  const bannerY = useParallax(scrollYProgress, windowDimensions.width <= 992 ? 0 : 50);
  const mainY = useParallax(scrollYProgress, windowDimensions.width <= 992 ? -600 : -1024);

  return (
    <>
      <div ref={mainRef}>
        <HeroSlider
          mainTransformY={mainY}
          bannerTransformY={bannerY}
          size="sm"
          btn={true}
          title={"The ultra modern creative connection."}
          isComingSoon={true}
          text={
            <>
              <Typography variant="subtitle">
                Forreels.io is the central hub for <span>hiring creatives worldwide</span> in the{" "}
                <span>film and photography industry,</span> find <span>creative opportunities</span> as a creative,{" "}
                <span>build,</span> organize and deliver your work so you that you{" "}
                <span>create mind-blowing products</span> on time, every time.
              </Typography>
            </>
          }
        />
        <EmailSubscription />
      </div>
    </>
  );
};
export default ComingSoon;
