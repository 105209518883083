import { MotionValue, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import {
  benderless,
  ciara,
  ciaraLogo,
  cover1,
  crush,
  crushLogo,
  customerSlider1,
  hope,
  hypergong,
  interstellar,
  jumalio,
  jumalioLogo,
  spaceX,
  spaceXLogo,
  uber,
  uberLogo,
  wildDreams,
  wildDreamsLogo,
  wolfboy,
} from "../../../assets";
import { Typography } from "../../../components/common/Base";
import { BlueSection } from "../../../components/common/Layout";
import {
  CustomSlider,
  HalfImageText,
  HeroSlider,
  ImageDescription,
  LogoSlider,
  PercentageText,
} from "../../../components/common/Widgets";
import "./style.scss";

function useParallax(motionValue: MotionValue<number>, distance: number) {
  return useTransform(motionValue, [0, 1], [-distance, distance]);
}

const Customers = () => {
  const halfImageSectionData = [
    {
      heading: "Set Design",
      title: "HyperGong 313 and it”s stellar award-winning editor.",
      text: "Forreels.io collaborated with SPACEX on their journey to Mars.",
      image: hope,
    },
    {
      heading: "Set Design",
      title: "HyperGong 313 and it”s stellar award-winning editor.",
      text: "Forreels.io collaborated with SPACEX on their journey to Mars.",
      image: interstellar,
    },
    {
      heading: "Set Design",
      title: "HyperGong 313 and it”s stellar award-winning editor.",
      text: "Forreels.io collaborated with SPACEX on their journey to Mars.",
      image: hypergong,
    },
    {
      heading: "Set Design",
      title: "HyperGong 313 and it”s stellar award-winning editor.",
      text: "Forreels.io collaborated with SPACEX on their journey to Mars.",
      image: wolfboy,
    },
    {
      heading: "Set Design",
      title: "HyperGong 313 and it”s stellar award-winning editor.",
      text: "Forreels.io collaborated with SPACEX on their journey to Mars.",
      image: benderless,
    },
  ];
  const mainRef = useRef(null);
  const [windowDimensions] = useWindowDimensions();

  const { scrollYProgress } = useScroll({ target: mainRef });
  const bannerY = useParallax(scrollYProgress, windowDimensions.width <= 992 ? 0 : 120);
  return (
    <>
      <div
        ref={mainRef}
        className="pb-5 section-01-styles"
      >
        <HeroSlider
          bannerTransformY={bannerY}
          size="lg"
          title="Create with confidence."
          text={
            <>
              <Typography
                variant="pre-title"
                className="subtitle-styles"
              >
                Forreels.io provides <span>structure</span>, artistic <span>development</span>, and
                <span> community</span> which helps creatives <span>focus on the art</span>.
              </Typography>
            </>
          }
        />
        <LogoSlider />
      </div>

      <BlueSection className="blue-section-02-styles">
        <div className="eclipse"></div>
        <div className="container text-button-container-02">
          <div
            className="text-wrapper-02"
            data-aos="fade-in"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <div className="subtitle-left-01">
              <Typography variant="pre-title">Explore the stories</Typography>
            </div>
            <div className="title-left">
              <Typography variant="h1">
                Building better <br /> creators.
              </Typography>
            </div>
            <div className="subtitle-left-02">
              <Typography variant="pre-title">
                Forreels.io Super Producers are renown for <span>assembling dream teams</span> that brings{" "}
                <span>cinematic visions to life</span>, with <span>proven track records</span> and extensive network of{" "}
                <span>industry connections</span>. With this <span>powerhouse producer</span> by your side, your project
                not only gains industry expertise but also a <span>passionate ambassador for its success.</span>
              </Typography>
            </div>
          </div>
        </div>
      </BlueSection>

      <div
        className="container hit-container"
        data-aos="fade-in"
        data-aos-easing="linear"
        data-aos-duration="500"
      >
        {halfImageSectionData.map((item, index) => (
          <HalfImageText
            key={item.image}
            align={index % 2 === 0 ? "left" : "right"}
            heading={item.heading}
            title={item.title}
            text={item.text}
            image={[
              {
                src: item.image,
                carousel: false,
              },
            ]}
            titleVariant="subtitle"
            textVariant="body-1"
            linkButton={true}
          />
        ))}

        <div className="row mt-5">
          <div
            className="col-12 col-md-6"
            data-aos="fade-right"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <ImageDescription
              cover={uber}
              logo={uberLogo}
              text={"UberEats uses Forreels.io to book their crew to create some tasty commercials."}
            />
          </div>
          <div
            className="col-12 col-md-6"
            data-aos="fade-left"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <ImageDescription
              cover={ciara}
              logo={ciaraLogo}
              text={"Ciara crews up with Forreels.io for her new single “FOMO”."}
            />
          </div>
          <div
            className="col-12 col-md-6"
            data-aos="fade-right"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <ImageDescription
              cover={spaceX}
              logo={spaceXLogo}
              text={"Forreels.io collaborated with SPACEX on their journey to Mars."}
            />
          </div>
          <div
            className="col-12 col-md-6"
            data-aos="fade-left"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <ImageDescription
              cover={crush}
              logo={crushLogo}
              text={"Master Hairstylist Giovanni Amar shares his top 5 film styling secrets from “Crush”."}
            />
          </div>
          <div
            className="col-12 col-md-6"
            data-aos="fade-right"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <ImageDescription
              cover={wildDreams}
              logo={wildDreamsLogo}
              text={"Forreels.io collaborated with SPACEX on their journey to Mars."}
            />
          </div>
          <div
            className="col-12 col-md-6"
            data-aos="fade-left"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <ImageDescription
              cover={jumalio}
              logo={jumalioLogo}
              text={"Master Hairstylist Giovanni Amar shares his top 5 film styling secrets from “Crush”."}
            />
          </div>
        </div>
      </div>

      <BlueSection className="blue-section-01-styles">
        <div className="text-button-container">
          <div
            className="text-wrapper"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <div className="subtitle-center-01">
              <Typography variant="subtitle">The set up for success</Typography>
            </div>
            <div className="title-center">
              <Typography variant="h1">
                Career growth <br /> you can see.
              </Typography>
            </div>
            <div className="subtitle-center-02">
              <Typography
                variant="pre-title"
                className="subtitle-stylingg"
              >
                Forreels.io <span>offers</span> substantial career growth solutions <span>to creatives that</span>{" "}
                provides the significant keys to success{" "}
                <span>
                  including seminars, discounted gear rental, educational courses, networking events and a platform that
                </span>{" "}
                helps manage, book and organize productions.
              </Typography>
            </div>
          </div>

          <div
            className="percentage-text-container mt-4"
            data-aos="fade-right"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <PercentageText
              number={35}
              text={"more successful, organized productions"}
            />
            <PercentageText
              number={60}
              text={"more clients meet rate or offer more"}
            />
            <PercentageText
              number={40}
              text={"more clients are repeat customers"}
            />
          </div>
        </div>
      </BlueSection>

      <div className="image-box">
        <img
          src={cover1}
          alt=""
          className="img-fluid"
          height={"100vh"}
        />
      </div>

      <BlueSection className="text-percentage-section">
        <div className="container">
          <div className="text-section">
            <div
              className="title-02 mb-4"
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              <Typography
                variant="h1"
                className="reel-growth-text"
              >
                Reel growth <br /> real fast.
              </Typography>
            </div>
            <div className="para-percent">
              <div
                className="subtitle-left-03"
                data-aos="fade-right"
                data-aos-easing="linear"
                data-aos-duration="500"
              >
                <Typography variant="pre-title">
                  Oh, the options. Forreels.io give you access to a selection of productions great for building a
                  full-bodied reel that identifies with you and attracts you to productions of your liking.
                  <br />
                  <br />
                  Watch your creative palette grow with unlimited, wide-ranging collaborations, with soon access from
                  across the world. Completely tailor your work experience, schedule and environment. Career location
                  freedom is the new wave.
                </Typography>
              </div>

              <div
                className="d-flex md-justify-content-center align-items-end"
                data-aos="fade-left"
                data-aos-easing="linear"
                data-aos-duration="500"
              >
                <PercentageText
                  number={40}
                  text="more productions"
                  topText="UP TO"
                />
              </div>
            </div>
          </div>
        </div>
      </BlueSection>

      <div className="slider-container">
        <CustomSlider
          centerMode={true}
          slides={[
            {
              img: customerSlider1,
              title: "PLAKAA",
              text: "“Forreels.io has allowed me complete filming freedom, fully booked across the globe all year.",
              personName: "Johnathon Furr              -",
              designation: "Co-Founder and President, PLAKAA",
            },
            {
              img: customerSlider1,
              title: "PLAKAA",
              text: "“Forreels.io has allowed me complete filming freedom, fully booked across the globe all year.",
              personName: "Johnathon Furr              -",
              designation: "Co-Founder and President, PLAKAA",
            },
            {
              img: customerSlider1,
              title: "PLAKAA",
              text: "“Forreels.io has allowed me complete filming freedom, fully booked across the globe all year.",
              personName: "Johnathon Furr              -",
              designation: "Co-Founder and President, PLAKAA",
            },
          ]}
        />
      </div>

      <BlueSection className="blue-section-styles">
        <div className="section-text">
          <div className="section-text-style">
            <Typography
              variant="h2"
              className="blue-section-text-center"
            >
              Join the 250,000 who collaborate with Forreels.io creatives.
            </Typography>
          </div>
        </div>
      </BlueSection>
    </>
  );
};

export default Customers;
