import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import {
  landingBottom1,
  landingBottom2,
  landingBottom3,
  landingBottom4,
  landingBottom5,
  landingBottom6,
  landingLeft1,
  landingLeft2,
  landingLeft3,
  landingLeft4,
  landingRight1,
  landingRight2,
  landingRight3,
  landingRight4,
  landingTop1,
  landingTop2,
  landingTop3,
} from "../../../../assets";
import { Button, Typography } from "../../Base";
import "./style.scss";

interface HeroSliderProps {
  mainTransformY?: object;
  bannerTransformY: object;
  size: string;
  btn?: boolean;
  title: string;
  text: string | JSX.Element;
  isComingSoon?: boolean;
}

const HeroSlider: React.FC<HeroSliderProps> = ({
  mainTransformY,
  bannerTransformY,
  size,
  btn,
  title,
  text,
  isComingSoon,
}) => {
  const [isFaded, setIsFaded] = useState(false);
  const [windowDimensions] = useWindowDimensions();

  useEffect(() => {
    const handleScroll = () => {
      const fadeStartPosition = windowDimensions.width <= 992 ? 60 : 300;
      const scrollPosition = window.scrollY;

      setIsFaded(size === "sm" ? scrollPosition > fadeStartPosition : false);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [windowDimensions.width, size]);

  return (
    <div
      className="heroSliderMain"
      style={isComingSoon ? { height: size === "sm" ? "150vh" : "100vh", overflow: "hidden" } : undefined}
    >
      <motion.section
        className={`banner-sec container ${size && size}`}
        style={mainTransformY}
      >
        {/* Desktop Top */}
        <img
          className={`desktop animate__fadeInUp animate__animated animate__slow ${isFaded && "animate__fadeOutUp"}`}
          style={{ top: "-80px", left: "200px" }}
          src={landingTop1}
          alt=""
        />
        <img
          className={`desktop animate__fadeInUp ${isFaded && "animate__fadeOutUp"}`}
          style={{ top: "-190px" }}
          src={landingTop2}
          alt=""
        />
        <img
          className={`desktop animate__fadeInUp animate__animated animate__slow ${isFaded && "animate__fadeOutUp"}`}
          style={{ top: "-80px", right: "200px" }}
          src={landingTop3}
          alt=""
        />

        {/* Desktop Left */}
        <img
          className={`desktop animate__fadeInLeft ${isFaded && "animate__fadeOutLeft animate__animated animate__slow"}`}
          style={{ top: "10px", left: "-200px" }}
          src={landingLeft1}
          alt=""
        />
        <img
          className={`desktop animate__fadeInLeft ${isFaded && "animate__fadeOutLeft animate__animated animate__slow"}`}
          style={{ top: "265px", left: "-200px" }}
          src={landingLeft2}
          alt=""
        />
        <img
          className={`desktop animate__fadeInLeft ${isFaded && "animate__fadeOutLeft animate__animated animate__slow"}`}
          style={{ top: "520px", left: "-200px" }}
          src={landingLeft3}
          alt=""
        />
        <img
          className={`desktop animate__fadeInLeft ${isFaded && "animate__fadeOutLeft animate__animated animate__slow"}`}
          style={{ top: "775px", left: "-200px" }}
          src={landingLeft4}
          alt=""
        />

        {/* Mobile Top */}
        <img
          className={`mobile animate__fadeInUp ${isFaded && "animate__fadeOutUp animate__animated animate__slow"}`}
          style={{ top: "15px", left: "-40px" }}
          src={landingLeft1}
          alt=""
        />
        <img
          className={`mobile animate__fadeInUp ${isFaded && "animate__fadeOutUp animate__animated animate__slow"}`}
          style={{ top: "135px", left: "-40px" }}
          src={landingTop1}
          alt=""
        />

        <img
          className={`mobile animate__fadeInUp ${isFaded && "animate__fadeOutUp animate__animated animate__slow"}`}
          style={{ top: "-35px", left: "135px" }}
          src={landingLeft3}
          alt=""
        />
        <img
          className={`mobile animate__fadeInUp ${isFaded && "animate__fadeOutUp animate__animated animate__slow"}`}
          style={{ top: "80px", left: "135px" }}
          src={landingTop2}
          alt=""
        />

        <img
          className={`mobile animate__fadeInUp ${isFaded && "animate__fadeOutUp animate__animated animate__slow"}`}
          style={{ top: "-80px", right: "-60px" }}
          src={landingRight3}
          alt=""
        />
        <img
          className={`mobile animate__fadeInUp ${isFaded && "animate__fadeOutUp animate__animated animate__slow"}`}
          style={{ top: "40px", right: "-60px" }}
          src={landingRight1}
          alt=""
        />
        <img
          className={`mobile animate__fadeInUp ${isFaded && "animate__fadeOutUp animate__animated animate__slow"}`}
          style={{ top: "160px", right: "-60px" }}
          src={landingTop3}
          alt=""
        />

        {/* Text container */}
        <motion.div
          className={`banner-text ${size && size}`}
          style={bannerTransformY}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 all-text-container">
                <h1
                  className={`animate__fadeInUp animate__animated animate__fast ${isFaded && "animate__fadeOutUp animate__animated animate__slow"}`}
                >
                  <Typography
                    variant="h1"
                    className="heading"
                  >
                    {title}
                  </Typography>
                </h1>
                <div
                  className={`animate__fadeInUp animate__animated animate__fast ${isFaded && "animate__fadeOutUp animate__animated animate__slow"}`}
                >
                  <Typography
                    variant="pre-title"
                    className="subtitle-style"
                  >
                    {text}
                  </Typography>
                </div>
                {btn && (
                  <a
                    href="#subscription-sec"
                    className="text-light"
                  >
                    <Button
                      className={`m-auto animate__animated animate__fast ${isFaded && "animate__fadeOutUp animate__animated animate__slow"}`}
                      color={"link"}
                      variant={"outlined"}
                    >
                      <Typography variant="button-text">{isComingSoon ? "Subscribe" : "Sign Up"}</Typography>
                    </Button>
                  </a>
                )}
              </div>
            </div>
          </div>
        </motion.div>

        {/* Mobile Bottom */}
        <img
          className={`mobile animate__fadeInUp ${isFaded && "animate__fadeOutUp animate__animated animate__slow"}`}
          style={{ bottom: "120px", left: "-40px" }}
          src={landingBottom1}
          alt=""
        />
        <img
          className={`mobile animate__fadeInUp ${isFaded && "animate__fadeOutUp animate__animated animate__slow"}`}
          style={{ bottom: "-10px", left: "-40px" }}
          src={landingBottom2}
          alt=""
        />

        <img
          className={`mobile animate__fadeInUp ${isFaded && "animate__fadeOutUp animate__animated animate__slow"}`}
          style={{ bottom: "80px", left: "135px" }}
          src={landingBottom3}
          alt=""
        />
        <img
          className={`mobile animate__fadeInUp ${isFaded && "animate__fadeOutUp animate__animated animate__slow"}`}
          style={{ bottom: "-50px", left: "135px" }}
          src={landingBottom4}
          alt=""
        />

        <img
          className={`mobile animate__fadeInUp ${isFaded && "animate__fadeOutUp animate__animated animate__slow"}`}
          style={{ bottom: "110px", right: "-60px" }}
          src={landingBottom5}
          alt=""
        />
        <img
          className={`mobile animate__fadeInUp ${isFaded && "animate__fadeOutUp animate__animated animate__slow"}`}
          style={{ bottom: "-20px", right: "-60px" }}
          src={landingBottom6}
          alt=""
        />

        {/* Desktop Right */}
        <img
          className={`desktop animate__fadeInRight ${isFaded && "animate__fadeOutRight animate__animated animate__slow"}`}
          style={{ top: "10px", right: "-200px" }}
          src={landingRight1}
          alt=""
        />
        <img
          className={`desktop animate__fadeInRight ${isFaded && "animate__fadeOutRight animate__animated animate__slow"}`}
          style={{ top: "265px", right: "-200px" }}
          src={landingRight2}
          alt=""
        />
        <img
          className={`desktop animate__fadeInRight ${isFaded && "animate__fadeOutRight animate__animated animate__slow"}`}
          style={{ top: "520px", right: "-200px" }}
          src={landingRight3}
          alt=""
        />
        <img
          className={`desktop animate__fadeInRight ${isFaded && "animate__fadeOutRight animate__animated animate__slow"}`}
          style={{ top: "775px", right: "-200px" }}
          src={landingRight4}
          alt=""
        />

        {/* Desktop Bottom */}
        <img
          className={`desktop animate__fadeInUp animate__animated animate__slow ${isFaded && "animate__fadeOutDown"}`}
          style={{ top: "685px", left: "200px" }}
          src={landingBottom1}
          alt=""
        />
        <img
          className={`desktop animate__fadeInUp animate__animated animate__slow ${isFaded && "animate__fadeOutDown"}`}
          style={{ top: "950px", left: "200px" }}
          src={landingBottom2}
          alt=""
        />

        <img
          className={`desktop animate__fadeInUp ${isFaded && "animate__fadeOutDown animate__animated animate__slow"}`}
          style={{ top: "800px" }}
          src={landingBottom3}
          alt=""
        />
        <img
          className={`desktop animate__fadeInUp ${isFaded && "animate__fadeOutDown animate__animated animate__slow "}`}
          style={{ top: "1065px" }}
          src={landingBottom4}
          alt=""
        />

        <img
          className={`desktop animate__fadeInUp animate__animated animate__slow ${isFaded && "animate__fadeOutDown"}`}
          style={{ top: "685px", right: "200px" }}
          src={landingBottom5}
          alt=""
        />
        <img
          className={`desktop animate__fadeInUp animate__animated animate__slow ${isFaded && "animate__fadeOutDown"}`}
          style={{ top: "950px", right: "200px" }}
          src={landingBottom6}
          alt=""
        />
      </motion.section>
    </div>
  );
};

export default HeroSlider;
