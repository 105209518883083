import * as Yup from "yup";

export const validationSchemaCreateAccount = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string()
    .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Enter a valid email address")
    .required("Email is required"),
  password: Yup.string().min(8, "Password must be at least 8 characters"),
  day: Yup.number().required("Day is required"),
  month: Yup.number().required("Month is required"),
  year: Yup.number().required("Year is required"),
});

export const validationSchemaForgotPassword = Yup.object().shape({
  email: Yup.string()
    .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Enter a valid email address")
    .required("Email is required"),
});

export const validationSchemaSignInEmail = Yup.object().shape({
  email: Yup.string()
    .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Enter a valid email address")
    .required("Email is required"),
});

export const validationSchemaCreateNewPassword = Yup.object().shape({
  confirmPassword: Yup.string().oneOf([Yup.ref("password")], "Passwords do not match"),
});

export const validationSchemaContactForm = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().required("Email address is required").email("Enter a valid email address"),
  companyName: Yup.string().required("Company name is required"),
  phone: Yup.string().min(7, "Invalid Phone Number").max(15, "Invalid Phone Number"),
  country: Yup.string().required("Country is required"),
  companySize: Yup.number().required("Company size is required"),
  videoDescription: Yup.string().required("Video Description is required"),
  roleDescription: Yup.string().required("Role description is required"),
  helpDescription: Yup.string().required("Help Description is required"),
  agreement: Yup.string().required("Agreement is required to proceed"),
});
