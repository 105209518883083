import { Footer, SimpleNavbar } from "../components/common/Layout";
import { ComingSoonDefault } from "../components/features";

const ComingSoon = () => (
  <>
    <SimpleNavbar />
    <ComingSoonDefault />
    <Footer />
  </>
);

export default ComingSoon;
