import React, { useState } from "react";
import { ChevronDown } from "react-feather";
import { Typography } from "..";
import "./styles.scss";

interface AccordionProps {
  title: string;
  content: string | JSX.Element;
}

const Accordion: React.FC<AccordionProps> = ({ title, content }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // Trigger your click handler on Enter or Space key press
    if (event.key === "Enter" || event.key === " ") {
      handleClick();
    }
  };

  return (
    <div className="accordion-container">
      <div
        className="title-icon"
        onClick={handleClick}
        onKeyDown={handleKeyPress}
        role="button"
        tabIndex={0}
      >
        <Typography
          variant={"body-1"}
          className="accordion-title"
        >
          {title}
        </Typography>
        <div className={`arrow-icon ${open ? "arrow-icon-open" : ""}`}>
          <ChevronDown className="chevron-icon" />
        </div>
      </div>
      {open && (
        <Typography
          className="content"
          variant={"body-1"}
        >
          {content}
        </Typography>
      )}
      <div className="accordion-divider"></div>
    </div>
  );
};

export default Accordion;
