import apiClient from "../apiClient";
import {
  CheckResponse,
  ChooseMethod,
  CreateNewPassword,
  Forgot,
  ForgotPassword,
  ForgotPasswordCheck,
  OTPVerification,
  SignIn,
  SignInEmail,
  SignUp,
  ValidateEmail,
  ValidateEmailResend,
} from "./auth.types";

export function handleCheck() {
  return apiClient.get<CheckResponse>("/auth/check");
}
export function handleSignUp(authCredentials: SignUp) {
  return apiClient.post("/auth/signup", authCredentials);
}

export function handleValidateEmail(validateEmail: ValidateEmail) {
  return apiClient.post("/auth/validate", validateEmail);
}

export function handleValidateEmailResend(validateEmailResend: ValidateEmailResend) {
  return apiClient.post("/auth/validate/resend", validateEmailResend);
}

export function handleForgot(forgotPassword: Forgot) {
  return apiClient.post("/auth/forgot", forgotPassword);
}

export function handleForgotCheck(forgotPasswordCheck: ForgotPasswordCheck) {
  return apiClient.post("/auth/forgot/check", forgotPasswordCheck);
}

export function handleForgotPassword(forgotPassword: ForgotPassword) {
  return apiClient.post("/auth/forgot/password", forgotPassword);
}

export function handleSignIn(authCredentials: SignIn) {
  return apiClient.post("/auth/signin", authCredentials);
}

export function handleSignInEmail(authCredentials: SignInEmail) {
  return apiClient.post("/auth/signin/email", authCredentials);
}

export function handleLogout() {
  return apiClient.get("/auth/logout");
}

export function handleChooseMethod(authCredentials: ChooseMethod) {
  return apiClient.post("/auth/otp/post", authCredentials);
}

export function handleOTPVerification(authCredentials: OTPVerification) {
  return apiClient.post("/auth/otp/verification", authCredentials);
}

export function handleCreateNewPassword(authCredentials: CreateNewPassword) {
  return apiClient.post("/auth/updateOpenPasswordOTP", authCredentials);
}
