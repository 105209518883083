import { useFormik } from "formik";
import { subscribe } from "src/api/subscribe/subscribe.service";
import { Typography } from "../../../../components/common/Base";
import "../../../../components/common/Base/AnimatedButton/styles.scss";
import "./style.scss";

const EmailSubscription = () => {
  const { values, handleSubmit } = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      subscribe(values)
        .then(() => {
          // scroll to top
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });
  return (
    <>
      <section
        className="subscription-sec"
        id="subscription-sec"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <Typography
                variant="h2"
                className="headingStyle"
              >
                Join the private <br />
                exclusive access
              </Typography>
              <section className="parent">
                <div className="cta">
                  <div className="cta__content">
                    <form
                      onSubmit={handleSubmit}
                      className="input-container"
                    >
                      <span>
                        <input
                          type="email"
                          name="query"
                          onChange={(e) => {
                            values.email = e.target.value;
                          }}
                          placeholder="example@mail.com"
                          autoComplete="off"
                          required
                        />
                      </span>

                      <span>
                        <button
                          type="submit"
                          className="subscribe-btn"
                        >
                          <Typography variant="button-text">Subscribe</Typography>
                        </button>
                      </span>
                    </form>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      {/* <AnimatedButton /> */}
    </>
  );
};

export default EmailSubscription;
